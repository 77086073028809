import * as React from "react"
import { graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useForm } from "react-hook-form"
import axios from "axios"
import "../contact.css"

const ContactForm = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = async data => {
    const {
      name,
      email,
      company,
      budget,
      websitePurpose,
      websiteType,
      neededFeatures,
    } = data

    let message = `会社: ${company},\n ウェブサイトの目的: ${websitePurpose},\n 希望するウェブサイトの種類: ${websiteType},\n 必要な機能: ${neededFeatures},\n ご予算: ${budget},\n`

    try {
      await axios
        .post("https://admin.jamstack-media.com/contact", {
          name,
          email,
          message,
        })
        .then(response => {
          console.log(response)
          navigate("/")
        })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <div className="image-heading">
        <StaticImage src="../images/contact.png" width={4000} alt="topの画像" />
      </div>
      <main className="contact">
        <div className="form-container">
          <form onSubmit={handleSubmit(onSubmit)}>
            <h2>お問い合わせフォーム</h2>
            <label>
              お名前:
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="例: jamstack 太郎"
              />
            </label>
            {errors.name && <p>This field is required</p>}
            <label>
              会社(任意):
              <input
                {...register("company")}
                type="text"
                placeholder="例: jamatckホームページ制作屋"
              />
            </label>
            <label>
              emailアドレス:
              <input
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                })}
                type="email"
                placeholder="例: sample@sample.com"
              />
            </label>
            {errors.email && <p>This field is required</p>}
            <p>ウェブサイトの目的</p>
            <label>
              <input
                {...register("websitePurpose")}
                type="checkbox"
                value="商品/サービスの販売"
              />
              商品/サービスの販売
            </label>
            <label>
              <input
                {...register("websitePurpose")}
                type="checkbox"
                value="ブランド認知度向上"
              />
              ブランド認知度向上
            </label>
            <label>
              <input
                {...register("websitePurpose")}
                type="checkbox"
                value="インフォメーション提供"
              />
              インフォメーション提供
            </label>
            <label>
              <input
                {...register("websitePurpose")}
                type="checkbox"
                value="ブログ/コンテンツ配信"
              />
              ブログ/コンテンツ配信
            </label>
            <label>
              <input
                {...register("websitePurpose")}
                type="checkbox"
                value="顧客サポート"
              />
              顧客サポート
            </label>

            <p>希望するウェブサイトの種類</p>
            <label>
              <input
                {...register("websiteType")}
                type="checkbox"
                value="ランディングページ"
              />
              ランディングページ
            </label>
            <label>
              <input
                {...register("websiteType")}
                type="checkbox"
                value="ブログサイト"
              />
              ブログサイト
            </label>
            <label>
              <input
                {...register("websiteType")}
                type="checkbox"
                value="eコマースサイト"
              />
              eコマースサイト
            </label>
            <label>
              <input
                {...register("websiteType")}
                type="checkbox"
                value="ポートフォリオサイト"
              />
              ポートフォリオサイト
            </label>
            <label>
              <input
                {...register("websiteType")}
                type="checkbox"
                value="ニュース/メディアサイト"
              />
              ニュース/メディアサイト
            </label>

            <p>必要な機能</p>
            <label>
              <input
                {...register("neededFeatures")}
                type="checkbox"
                value="ショッピングカート"
              />
              ショッピングカート
            </label>
            <label>
              <input
                {...register("neededFeatures")}
                type="checkbox"
                value="お問い合わせフォーム"
              />
              お問い合わせフォーム
            </label>
            <label>
              <input
                {...register("neededFeatures")}
                type="checkbox"
                value="ブログ/ニュースセクション"
              />
              ブログ/ニュースセクション
            </label>
            <label>
              <input
                {...register("neededFeatures")}
                type="checkbox"
                value="会員登録/ログイン機能"
              />
              会員登録/ログイン機能
            </label>

            <p>ご予算</p>
            <label>
              <input {...register("budget")} type="radio" value="〜50万円" />
              〜50万円
            </label>
            <label>
              <input
                {...register("budget")}
                type="radio"
                value="50万円〜100万円"
              />
              50万円〜100万円
            </label>
            <label>
              <input
                {...register("budget")}
                type="radio"
                value="100万円〜500万円"
              />
              100万円〜500万円
            </label>
            <label>
              <input
                {...register("budget")}
                type="radio"
                value="500万円〜1000万円"
              />
              500万円〜1000万円
            </label>
            <label>
              <input {...register("budget")} type="radio" value="1000万円〜" />
              1000万円〜
            </label>
            <input type="submit" value="この内容でお問い合わせをする。" />
          </form>
        </div>
      </main>
    </Layout>
  )
}

export default ContactForm

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="お問い合わせ"
    description="お問い合わせはこちらから。お気軽にご連絡ください。お客様のお悩みを解決するために全力でサポートいたします。"
    cover={"/main.png"}
  />
)

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
